<template>
  <div id="app">
    <router-view v-if="isRouterAlive"/>
    <van-tabbar route v-model="active" v-if="!$route.meta.hideTabBar">
      <van-tabbar-item replace to="/index" name="index" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/mine" name="mine" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
  export default {
    provide() {
      return {
        goUrl: this.goUrl,
        isEdit: this.isEdit,
        add: this.add,
        update: this.update,
        getStorage: this.getStorage,
        setStorage: this.setStorage,
        clearStorage: this.clearStorage,
        reload: this.reload,
      }
    },
    data() {
      return {
        active: 'home',
        roles: [],
        isRouterAlive: true
      }
    },
    created() {
    },
    methods:{
      goUrl(url) {
        this.$router.push(url)
      },
      reload() {
        this.isRouterAlive = false
        this.$nextTick(function() {
          this.isRouterAlive = true
        })
      },
      isEdit() {
        return this.$route.params.id || false
      },
      add(API, data, message, url) {
        API.create(data).then(res => {
          const resId = res.data.res
          if (res.data.error_code !== 1) {
            console.log(res)
            this.$toast(res.data.message)
          } else {
            this.$toast.success(message || '新增成功')
            if (url) {
              this.goUrl(url + resId)
            } else {
              this.$router.go(-1)
            }
          }
        })
      },

      update(API, data, message, url) {
        API.update(data).then(res => {
          if (res.data.error_code !== 1) {
            this.$toast(res.data.message)
          } else {
            this.$toast.success(message || '更新成功')
            if (url) {
              this.goUrl(url)
            } else {
              this.$router.go(-1)
            }
          }
        })
      },
      getStorage(key) {
        const str = localStorage.getItem(key)
        const data = JSON.parse(str)
        return data
      },
      setStorage(key, data) {
        window.localStorage.setItem(key, JSON.stringify(data))
      },
      clearStorage(key) {
        window.localStorage.removeItem(key)
      }
    }
  }
</script>
<style>
</style>
