import { createRouter, createWebHistory } from 'vue-router'

export const constantRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      hideTabBar: true,//在路由meta对象设置一个布尔值showTabBar
      'title': '登录'
    },
    component: () => import('@/views/Login/')
  }
]

export const asyncRoutes = [
  {
    path: '/async',
    name: 'home',
    meta: {
      title: '首页'
    },
    component: () => import('@/views/Index/'),
    children: [
      {
        path: '/',
        redirect: '/index'
      },
      {
        path: '/index',
        name: 'index',
        meta: {
          title: '首页',
        },
        component: () => import('@/views/Index/'),
      },
    ]
  },
  {
    path: '/mine',
    name: 'mine',
    meta: {
      title: '个人中心',
    },
    component: () => import('@/views/Mine/index.vue'),
  },
  {
    path: '/mine',
    name: 'mine',
    component: () => import('@/views/Mine/index.vue'),
    meta: {
      title: '我的'
    }
  },
  {
    path: '/asset',
    name: 'asset',
    component: () => import('@/views/assets/index.vue'),
    meta: {
      title: '资产查询'
    }
  },
  {
    path: '/asset/edit/:id',
    name: 'assetEdit',
    component: () => import('@/views/assets/detail.vue'),
    meta: {
      title: '资产查询'
    }
  },
  {
    path: '/parts',
    name: 'parts',
    component: () => import('@/views/parts/index.vue'),
    meta: {
      title: '备件查询'
    }
  },
  {
    path: '/parts/edit/:id',
    name: 'partsEdit',
    component: () => import('@/views/parts/detail.vue'),
    meta: {
      title: '备件查询'
    }
  },
  {
    path: '/workorder/pm1',
    name: 'pm1',
    component: () => import('@/views/pm1/index.vue'),
    meta: {
      title: '自主点检'
    }
  },
  {
    path: '/workorder/pm1/edit/:id',
    name: 'pm1edit',
    component: () => import('@/views/pm1/detail.vue'),
    meta: {
      title: '自主点检详情'
    }
  },
  {
    path: '/workorder/pm2',
    name: 'pm2',
    component: () => import('@/views/pm2/index.vue'),
    meta: {
      title: '自主维护'
    }
  },
  {
    path: '/workorder/pm2/edit/:id',
    name: 'pm2edit',
    component: () => import('@/views/pm2/detail.vue'),
    meta: {
      title: '自主维护详情'
    }
  },
  {
    path: '/project/apply',
    name: 'apply',
    component: () => import('@/views/apply/index.vue'),
    meta: {
      title: '历史报修'
    }
  },
  {
    path: '/project/apply/add',
    name: 'applyAdd',
    component: () => import('@/views/apply/detail.vue'),
    meta: {
      title: '新增报修'
    }
  },
  {
    path: '/project/apply/edit/:id',
    name: 'applyEdit',
    component: () => import('@/views/apply/detail.vue'),
    meta: {
      title: '新增报修详情'
    }
  },
  {
    path: '/workorder/pm3',
    name: 'pm3',
    component: () => import('@/views/pm3/index.vue'),
    meta: {
      title: '专业维护'
    }
  },
  {
    path: '/workorder/pm3/edit/:id',
    name: 'pm3edit',
    component: () => import('@/views/pm3/detail.vue'),
    meta: {
      title: '专业维护详情'
    }
  },
  {
    path: '/workorder/pm4',
    name: 'pm4',
    component: () => import('@/views/pm4/index.vue'),
    meta: {
      title: '加润滑油'
    }
  },
  {
    path: '/workorder/pm4/edit/:id',
    name: 'pm4edit',
    component: () => import('@/views/pm4/detail.vue'),
    meta: {
      title: '加润滑油详情'
    }
  },
  {
    path: '/workorder/pm5',
    name: 'pm5',
    component: () => import('@/views/pm5/index.vue'),
    meta: {
      title: '专业点检'
    }
  },
  {
    path: '/workorder/pm5/edit/:id',
    name: 'pm5edit',
    component: () => import('@/views/pm5/detail.vue'),
    meta: {
      title: '专业点检详情'
    }
  },
  {
    path: '/workorder/pm6',
    name: 'pm6',
    component: () => import('@/views/pm6/index.vue'),
    meta: {
      title: '专业巡检'
    }
  },
  {
    path: '/workorder/pm6/edit/:id',
    name: 'pm6edit',
    component: () => import('@/views/pm6/detail.vue'),
    meta: {
      title: '专业巡检详情'
    }
  },
  {
    path: '/workorder/pm7',
    name: 'pm7',
    component: () => import('@/views/pm7/index.vue'),
    meta: {
      title: '节日维保'
    }
  },
  {
    path: '/workorder/pm7/edit/:id',
    name: 'pm7edit',
    component: () => import('@/views/pm7/detail.vue'),
    meta: {
      title: '节日维保详情'
    }
  },
  {
    path: '/workorder/cm',
    name: 'pm9',
    component: () => import('@/views/cm/index.vue'),
    meta: {
      title: '故障报修'
    }
  },
  {
    path: '/workorder/cm/edit/:id',
    name: 'pm9edit',
    component: () => import('@/views/cm/detail.vue'),
    meta: {
      title: '故障报修详情'
    }
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes,
})

export function resetRouter() {
  const newRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: constantRoutes
  })
  router.matcher = newRouter.matcher // reset router
}

export default router
