import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import Vant from 'vant'
import 'vant/lib/index.css'
import './assets/iconfont.css'
import './assets/iconfont'
import './style/style.css'
import 'amfe-flexible'
import './permission'
//
// import Vconsole from 'vconsole'
// new Vconsole()

createApp(App).use(store).use(router).use(Vant).mount('#app')
